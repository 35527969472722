import _ from 'lodash';
import _isArray from 'lodash/isArray';
import _keys from 'lodash/keys';
import _pickBy from 'lodash/pickBy';
import { CategoryPotencyRanges } from 'shared/constants';
import { formatWeightOptions, getFormattedPotency, getPotencyValues, isPotencyProduct } from 'shared/helpers/products';
import { hasSaleSpecialForMenuType } from 'shared/helpers/specials';

export { getFormattedPotency };

export function getPotencyStrings(product) {
  const potencyValues = getPotencyValues(product);
  const potencyStrings = getFormattedPotency(product);
  const showTHC = isPotencyProduct(product) && parseFloat(potencyValues.THC) > 0;
  const showCBD = isPotencyProduct(product) && parseFloat(potencyValues.CBD) > 0;
  const showTAC = isPotencyProduct(product) && parseFloat(potencyValues.TAC) > 0;
  return {
    THC: showTHC ? potencyStrings.THC : null,
    CBD: showCBD ? potencyStrings.CBD : null,
    TAC: showTAC ? potencyStrings.TAC : null,
  };
}

export function rangeIsValid(range, maxRange) {
  if (!range || _.some(range, (value) => !_.inRange(value, maxRange[0], maxRange[1] + 1))) {
    return false;
  }
  return true;
}

export function getPricesForMenuType({ product, menuType, returnSaleSpecialPrices = false }) {
  const { recPrices, medicalPrices, recSpecialPrices, medicalSpecialPrices } = product;
  const isMed = menuType === 'med';
  const prices = isMed ? medicalPrices : recPrices;
  const specialPrices = isMed ? medicalSpecialPrices : recSpecialPrices;

  return returnSaleSpecialPrices ? specialPrices : prices;
}

export function getOptionsQualifyingForOffer({ product, offerId }) {
  const matchingOffer = _.find(product.specialData?.bogoSpecials, ({ specialId }) => specialId === offerId);
  return matchingOffer?.qualifyingOptions || [];
}

// Returns an array of objects containing all relevant info for each option:
// example: [{ value: '1g', label: '1g', price: '10.00' }, { value: '1/8oz', label: '1/8oz', price: '40.00' }]
export function groupOptionsWithPrices({ product, menuType, isKiosk, saleSpecial, offerSpecial, specialId }) {
  const {
    limitsPerCustomer,
    manualInventory,
    Options: options,
    optionsBelowKioskThreshold,
    optionsBelowThreshold,
    POSMetaData,
  } = product;
  const returnSaleSpecialPrices = _.isUndefined(saleSpecial)
    ? hasSaleSpecialForMenuType({ product, menuType })
    : saleSpecial;

  const prices = getPricesForMenuType({
    product,
    menuType,
    returnSaleSpecialPrices,
  });

  const qualifyingOptions = getOptionsQualifyingForOffer({ product, offerId: specialId });

  return formatWeightOptions({
    isKiosk,
    limitsPerCustomer,
    manualInventory,
    options,
    optionsBelowKioskThreshold,
    optionsBelowThreshold,
    prices,
    weightOnlyLabel: true,
    POSMetaData,
    showQualifyingOptions: !!offerSpecial,
    qualifyingOptions,
  });
}

const parsedProductsWeakMap = new WeakMap();
// this method is evil and should be 100% server side instead
export function parseProduct(product) {
  if (!product) {
    return product;
  }

  const cachedResult = parsedProductsWeakMap.get(product);
  if (cachedResult) {
    return cachedResult;
  }

  // These conversions are needed to support existing shape of product data.
  // Eventually these should be moved to the graphQl layer
  const { effects } = product;
  const parsedProduct = {
    ...product,
    // if object, filter out any key with a falsy value
    effects: _isArray(effects) ? effects : _keys(_pickBy(effects, (effect) => !!effect)),
    description: product.description || product.Description,
    name: product.name || product.Name,
    brand: product.brand || {},
  };

  parsedProductsWeakMap.set(product, parsedProduct);

  return parsedProduct;
}

export function potencyIsDefault(potencyRange = [], category) {
  const defaultRange = _.get(CategoryPotencyRanges, category?.key, []);
  return potencyRange[0] === defaultRange[0] && potencyRange[1] === defaultRange[1];
}

export function cartItemId(item) {
  const additionalOption = item.additionalOption ? item.additionalOption : _.head(item.product?.AdditionalOptions);
  return `${item.product.id}-${item.option}${additionalOption ? `-${additionalOption}` : ''}`;
}
