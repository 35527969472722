import React from 'react';
import styled, { css } from 'styled-components';
import { space, SpaceProps } from 'styled-system';

import Drawer from '@material-ui/core/Drawer';
import { makeStyles } from '@material-ui/core';

import { ModalExitIcon } from 'shared/modals/v2-container';
import { heading3 } from 'components/core/typography';
import { BackButton } from './back-button';

const commonStyles = {
  root: {
    // We need an !important here to override mui inline styles
    zIndex: `1400 !important` as any,
  },
  paper: { padding: `25px 25px 50px`, zIndex: 1500 },
};

const usePartialHeightStyles = makeStyles({
  ...commonStyles,
  paper: { ...commonStyles.paper, borderRadius: `23px 23px 0px 0px` },
});

const useFullHeightStyles = makeStyles({
  ...commonStyles,
  paper: { ...commonStyles.paper, height: `100%` },
});

export type BottomDrawerProps = {
  backButtonCopy?: string;
  handleBackButtonClick?: () => void;
  children: React.ReactNode;
  open: boolean;
  onClose?: () => void;
  heading?: string;
  subheading?: string;
  hideExitIcon?: boolean;
  fullHeight?: boolean;
  shouldCalculateIframeBottom?: boolean;
  iframeHeight?: number | null;
  viewportHeight?: number | null;
  parentOffset?: number | null;
  isIframe?: boolean;
};

export function BottomDrawer({
  backButtonCopy,
  handleBackButtonClick,
  children,
  onClose,
  heading,
  subheading,
  hideExitIcon,
  fullHeight,
  shouldCalculateIframeBottom = false,
  iframeHeight = null,
  viewportHeight = null,
  parentOffset = null,
  isIframe = false,
  ...props
}: BottomDrawerProps): JSX.Element {
  const partialHeightClasses = usePartialHeightStyles();
  const fullHeightClasses = useFullHeightStyles();
  const classes = fullHeight ? fullHeightClasses : partialHeightClasses;
  const bottomPosition =
    shouldCalculateIframeBottom && isIframe && iframeHeight && viewportHeight && parentOffset !== null
      ? Math.max(iframeHeight - viewportHeight - parentOffset, 0)
      : null;

  return (
    <StyledDrawer anchor='bottom' classes={classes} onClose={onClose} $bottomPosition={bottomPosition} {...props}>
      {heading && <Heading>{heading}</Heading>}
      {subheading && <Subheading>{subheading}</Subheading>}
      {backButtonCopy && handleBackButtonClick && <BackButton copy={backButtonCopy} onClick={handleBackButtonClick} />}
      {!hideExitIcon && !!onClose && <StyledExitIcon onClick={onClose} />}
      {children}
    </StyledDrawer>
  );
}

const StyledDrawer = styled(Drawer)`
  .MuiBackdrop-root {
    background-color: ${({ theme, isBackgroundVisible }) => !isBackgroundVisible && theme.colors.greyOverlay};
  }
  > .MuiPaper-root.MuiDrawer-paper {
    /* apply bottom padding only on iOS since Safari's bottom menu bar was interfering w buttons */
    @supports (-webkit-touch-callout: none) {
      padding-bottom: calc(49px + env(safe-area-inset-bottom));
    }

    /* Fix for positioning in iframe */
    ${({ $bottomPosition }) =>
      $bottomPosition !== null &&
      css`
        bottom: ${$bottomPosition}px !important;
      `}
  }
`;

const StyledExitIcon = styled(ModalExitIcon)`
  top: 25px;
  right: 23px;
  svg {
    fill: ${({ theme }) => theme.colors.grey[35]};
  }
`;

const Heading = styled.h3`
  ${heading3}
  color: ${({ theme }) => theme.colors.primaryBlack};
`;

const Subheading = styled.p`
  color: ${({ theme }) => theme.colors.grey[45]};
  font-size: 12px;
  margin-top: 8px;
`;

type ElevatedSectionProps = SpaceProps & {
  borderPosition: 'bottom' | 'top';
};

export const ElevatedSection = styled.div<ElevatedSectionProps>`
  ${({ theme, borderPosition = `bottom` }) =>
    borderPosition === 'top'
      ? css`
          border-top: 1px solid ${theme.colors.grey[90]};
          box-shadow: 0px -3px 1px ${theme.colors.sectionShadow};
        `
      : css`
          border-bottom: 1px solid ${theme.colors.grey[90]};
          box-shadow: 0px 3px 1px ${theme.colors.sectionShadow};
        `}
  margin: 0 -25px;
  padding: 0 25px;
  ${space}
`;
