import useProductQueryParams from 'src/hooks/use-product-query-params';
import useCart from 'src/hooks/use-cart';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useParticipatingInPersonalization } from 'src/utils/personalization/helpers';

function deepEqual(obj1: any, obj2: any): boolean {
  if (obj1 === obj2) {
    return true;
  }

  return obj1.length === obj2.length && obj1.every((value: number | string, index: number) => value === obj2[index]);
}

function useCommonSkipRules(): boolean {
  const flags = useFlags();

  const serveMedical = flags['growth.ads.serve-medical.rollout'] ?? false;
  const { menuType } = useCart();
  const { queryParams: qp, defaults } = useProductQueryParams();
  const { page, brands, subcategories, straintypes, potencythc, potencycbd, effects, sortby } = qp;

  const isValidMenu = menuType === 'rec' || menuType === 'both' || (menuType === 'med' && serveMedical);
  const hadValidPageNum = page === 1;
  const hasBrandsFilter = !!brands.length;
  const hasSubcategoryFilter = !deepEqual(subcategories, defaults.subcategories);
  const hasStrainTypesFilter = !deepEqual(straintypes, defaults.straintypes);
  const hasPotencyThcFilter = !deepEqual(potencythc, defaults.potencythc);
  const hasPotencyCbdFilter = !deepEqual(potencycbd, defaults.potencycbd);
  const hasEffectFilter = !deepEqual(effects, defaults.effects);
  const hasValidSortBy = ['default', 'popular', 'relevance'].includes(sortby);

  return (
    !hadValidPageNum ||
    !isValidMenu ||
    hasBrandsFilter ||
    hasSubcategoryFilter ||
    hasStrainTypesFilter ||
    hasPotencyThcFilter ||
    hasPotencyCbdFilter ||
    hasEffectFilter ||
    !hasValidSortBy
  );
}

export function useShouldSkipPersonalization(): boolean {
  const flags = useFlags();
  const isCommonSkip = useCommonSkipRules();
  const isParticipatingInPersonalization = useParticipatingInPersonalization();

  const isPersonalizationKillSwitchOn = flags['growth.personalization.queries-kill-switch.operational'] ?? false;

  if (isPersonalizationKillSwitchOn) {
    return true;
  }

  return isCommonSkip || !isParticipatingInPersonalization;
}

export function useShouldSkipLegacyPersonalization(): boolean {
  const flags = useFlags();

  const isLegacyPersonalizationKillSwitchOn =
    flags['growth.personalization.legacy.queries-kill-switch.operational'] ?? false;

  return isLegacyPersonalizationKillSwitchOn;
}
