/* eslint-disable @typescript-eslint/naming-convention */
import _ from 'lodash';
import { action, computed, observable, makeObservable } from 'mobx';
import { InstoreCartItem, SubtotalItem, InstoreCartResponse } from '../hooks/use-get-instore-cart/types';

export enum LoginSteps {
  EMAIL = 'email',
  PASSWORD = 'password',
  RESET_PASSWORD = 'resetPassword',
}

export enum CartViews {
  LOADING = 'loading',
  LOGIN = 'login',
  BARCODE = 'barcode',
  CART = 'cart',
  ERROR = 'error',
  COMPLETE_ACCOUNT = 'completeAccount',
}

export const defaultTipData = [
  {
    id: 1,
    amount: 0,
    percentage: 10,
    default: true,
  },
  {
    id: 2,
    amount: 400,
    percentage: 15,
  },
  {
    id: 3,
    amount: 600,
    percentage: 20,
  },
  {
    id: 4,
    amount: 800,
    percentage: 25,
  },
  {
    id: 5,
    amount: `other`,
    percentage: 0,
  },
];

export type InstoreCartDispensary = {
  name?: string;
  logo?: string;
  id?: string;
};

export type TipType = {
  amount: number | string;
  associateTerm: string;
};

export type TipDataType = {
  id: number;
  amount: number | string;
  percentage: number;
  default?: boolean;
};

export default class InstoreCart {
  @observable _tip: number | null = null;
  @observable associateTerm = `Budtender`;
  @observable dispensary: InstoreCartDispensary = {
    name: '',
    logo: '',
    id: '',
  };

  @observable currentView: CartViews = CartViews.LOADING;
  @observable cartToken: string[] | string = '';
  @observable currentLoginStep = LoginSteps.EMAIL;
  @observable isValidCartUser = false;
  @observable currency = `USD`;
  @observable discounts: SubtotalItem[] = [];
  @observable fees: SubtotalItem[] = [];
  @observable id = '';
  @observable items: InstoreCartItem[] = [];
  @observable subtotalCents = 0;
  @observable taxes: SubtotalItem[] = [];
  @observable tipCents = 0;
  @observable tippingDisabled = true;
  @observable totalCents = 0;

  @observable tipData: TipDataType[] = defaultTipData;

  constructor() {
    makeObservable(this);
  }

  @computed
  get tip(): number {
    const defaultTip = this.tipData.find((tip) => tip.default)?.amount ?? 0;
    const defaultTipAmount = _.isNumber(defaultTip) ? defaultTip : 0;

    return this._tip === null ? defaultTipAmount : this._tip;
  }

  set tip(amount: number) {
    this._tip = amount;
  }

  @computed
  get totalPrice(): number {
    return this.totalCents + this.tip;
  }

  @computed
  get totalDiscounts(): number | null {
    return this.discounts.length ? _.sum(this.discounts.map((discount) => discount.valueCents)) : null;
  }

  @computed
  get totalFees(): number | null {
    return this.fees.length ? _.sum(this.fees.map((fee) => fee.valueCents)) : null;
  }

  @computed
  get totalTaxes(): number {
    return _.sum(this.taxes.map((tax) => tax.valueCents));
  }

  @action
  dataRequested(requests: boolean[]): void {
    const isLoading = requests.some((status) => status);

    if (isLoading) {
      this.currentView = CartViews.LOADING;
    }
  }

  @action
  userDataReceived(isMissingUserInfo, isLoggedIn, isUserLoading, isEnrolledDutchiePay, cartToken): void {
    const isUserLoggedIn = !isUserLoading && isLoggedIn;
    this.isValidCartUser = isUserLoggedIn && isEnrolledDutchiePay;

    if (isUserLoggedIn && isMissingUserInfo) {
      this.currentView = CartViews.COMPLETE_ACCOUNT;
    } else if (isUserLoggedIn && cartToken) {
      this.currentView = CartViews.CART;
    } else if (this.isValidCartUser) {
      this.currentView = CartViews.BARCODE;
    }
  }

  @action
  setCurrentView(view: CartViews): void {
    this.currentView = view;
  }

  @action
  setCartToken(cartToken: string[] | string): void {
    this.cartToken = cartToken;
  }

  @action
  setCurrentLoginStep(step: LoginSteps): void {
    this.currentLoginStep = step;
  }

  @action
  resetDispensary(): void {
    this.dispensary = {
      name: '',
      logo: '',
      id: '',
    };
  }

  @action
  setInstoreCart({
    currency,
    discounts,
    fees,
    items,
    subtotalCents,
    taxes,
    tipCents,
    tippingDisabled,
    totalCents,
    dispensary,
    dispensaryId,
    id,
  }: InstoreCartResponse): void {
    this.currentView = CartViews.CART;
    this.currency = currency;
    this.discounts = discounts;
    this.fees = fees;
    this.id = id;
    this.items = items;
    this.subtotalCents = subtotalCents;
    this.taxes = taxes;
    this.tipCents = tipCents;
    this.totalCents = totalCents;
    this.tippingDisabled = tippingDisabled;
    this.dispensary = {
      name: dispensary?.name,
      logo: dispensary?.logo,
      id: dispensaryId,
    };
  }
}
