import React, { forwardRef, memo, useContext } from 'react';
import _ from 'lodash';

import OffersCarouselCard from 'shared/components/specials/offers-carousel-card';
import EmptyLoadingState from 'components/empty-loading-state';
import OffersSlider from 'components/carousel-slider/offers/offers-slider';
import useTranslation from 'hooks/use-translation';
import useUI from 'hooks/use-ui';
import { applySettings, CarouselConfigurationContext } from '../carousels.utils';
import { Section } from '../carousels.styles';
import { CarouselHeader } from '../carousel-header';

const generateSlides = ({ applicableSpecials = [], isSingularCard }) =>
  _.map(applicableSpecials, (special, index) => (
    <OffersCarouselCard
      key={special._id}
      aria-label={special.menuDisplayName}
      aria-roledescription='slide'
      className={`offers ${index !== 0 && index + 1 === applicableSpecials.length ? `last-card` : ``}`}
      isSingularCard={isSingularCard}
      displayName={special.menuDisplayName || special.name}
      displayImage={special.menuDisplayImage}
      enableRightMargin={applicableSpecials.length > 3}
      role='group'
      specialId={special._id}
      width='100%'
    />
  ));

export const OffersCarousel = memo(
  forwardRef((props, ref) => {
    const {
      carouselType = `products`,
      displayGradient = true,
      hasBeenVisible,
      href,
      isLoading,
      label,
      linkLabel,
      query,
      route,
      section,
      showLink = true,
      specials,
    } = props;
    const showTitle = section.hideTitle !== `true`;

    const { t } = useTranslation();
    const UI = useUI();
    const labelId = `${section.id}-label`;
    const applicableSpecials = specials && applySettings(specials);
    const isSingularCard = applicableSpecials?.length === 1;

    const { useLazyLoading } = useContext(CarouselConfigurationContext);

    const carouselProps = {
      className: carouselType,
      displayGradient,
      isSingularCard,
    };

    // provide accessible label info
    if (showTitle) {
      carouselProps[`aria-labelledby`] = labelId;
    } else {
      carouselProps[`aria-label`] = label;
    }

    const slides = generateSlides({
      applicableSpecials,
      isSingularCard,
    });

    // Render if Lazy Loading is disabled.
    // If lazy loading is disabled, render if been visible.
    const shouldRenderCarousel = !useLazyLoading || hasBeenVisible;

    return (
      // refs are stupid and get gobbled up in styled-components
      // we also need some default height here so that we get a sense of
      // where the visible components should lay
      <div ref={ref}>
        {shouldRenderCarousel && (
          <EmptyLoadingState isLoading={isLoading} page='productCarousel'>
            <Section data-cy={section.label} data-test={section.label} data-testid='product-carousel'>
              {(showLink || showTitle) &&
                CarouselHeader({
                  href,
                  isEmbeddedCarousel: UI.isEmbeddedCarousel,
                  label,
                  labelId,
                  linkLabel,
                  query,
                  route,
                  showLink,
                  showTitle,
                  t,
                })}
              <OffersSlider carouselProps={carouselProps}>{slides}</OffersSlider>
            </Section>
          </EmptyLoadingState>
        )}
      </div>
    );
  }),
  arePropsEqual
);

function arePropsEqual(prevProps, nextProps) {
  const isVisible = prevProps.hasBeenVisible === nextProps.hasBeenVisible;
  const finishedLoading = prevProps.isLoading === nextProps.isLoading;
  const hasMenuTypeChanged = prevProps.isMedical === nextProps.isMedical;
  return isVisible && finishedLoading && hasMenuTypeChanged;
}
