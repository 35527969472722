import _defineProperty from "/ecomm-marketplace/node_modules/next/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _keys from "lodash/keys";
import _find from "lodash/find";

var _paymentMethodsToDisp;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

export var OrderFlows = {
  pickup: ['open', 'confirmed', 'closed'],
  delivery: ['open', 'confirmed', 'inTransit', 'closed']
};
export var MARKETING_CAMPAIGN_PARAMS = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'];
export var SPECIAL_INSTRUCTIONS_MAX_LENGTH = 500;
export var CURBSIDE_PICKUP_SPECIAL_INSTRUCTIONS = 'This order is for Curbside Pickup.\n';
export var DRIVE_THRU_PICKUP_SPECIAL_INSTRUCTIONS = 'This is a drive thru order.\n';
export var DELIVERY_CUSTOMER_ERROR = 'cannot submit multiple orders from same customer to this dispensary on same day';
export var DELIVERY_ADDRESS_ERROR = 'cannot submit multiple orders from same address to this dispensary on same day';
export var PAUSED_ORDERS_CLOSED_ERROR = "We're closed at the moment due to extremely high demand. Stay tuned though, we'll reopen soon!";
export var DELIVERY_ERRORS = {
  CUSTOMER: 'CUSTOMER',
  ADDRESS: 'ADDRESS'
};
export var POSTAL_CODE_ERROR = 'We were unable to verify your card information. Please ensure the postal code entered matches what is on file at your bank for this card.';
export var OrderFilterOptions = [{
  name: 'delivery-available',
  key: 'deliveryAvailable',
  label: 'Delivery Available'
}, {
  name: 'free-delivery',
  key: 'freeDelivery',
  label: 'Free Delivery'
}, {
  name: 'no-delivery-minimum',
  key: 'noDeliveryMinimum',
  label: 'No Delivery Minimum'
}, {
  name: 'accepts-credit-cards',
  key: 'acceptsCreditCards',
  label: 'Accepts Credit Cards'
}];
export var PAYMENT_CASH = 'cash';
export var PAYMENT_DUTCHIEPAY = 'dutchiePay';
export var PAYMENT_CREDIT_CARD = 'creditCard'; // Order of these keys is important for
// firstValidPaymentOption to work correctly.
// We want to start with in store, then cash

export var paymentMethodsToDisplayNames = (_paymentMethodsToDisp = {
  payInStore: 'In Store',
  inStore: 'In Store'
}, _defineProperty(_paymentMethodsToDisp, PAYMENT_CASH, 'Cash'), _defineProperty(_paymentMethodsToDisp, "check", 'Check'), _defineProperty(_paymentMethodsToDisp, PAYMENT_CREDIT_CARD, 'Credit Card'), _defineProperty(_paymentMethodsToDisp, "creditCardAtDoor", 'Credit Card'), _defineProperty(_paymentMethodsToDisp, "payOnlineHypur", 'Hypur'), _defineProperty(_paymentMethodsToDisp, "payOnlineMerrco", 'Credit Card'), _defineProperty(_paymentMethodsToDisp, "payOnlineMoneris", 'Credit Card'), _defineProperty(_paymentMethodsToDisp, "payOnlineChase", 'Credit Card'), _defineProperty(_paymentMethodsToDisp, "creditCardByPhone", 'Pay by Phone'), _defineProperty(_paymentMethodsToDisp, "inStoreCreditCard", 'Credit Card'), _defineProperty(_paymentMethodsToDisp, "credit", 'Credit Card'), _defineProperty(_paymentMethodsToDisp, "debitCard", 'Debit Card'), _defineProperty(_paymentMethodsToDisp, "debitOnly", 'Debit Card'), _defineProperty(_paymentMethodsToDisp, "debit", 'Debit Card'), _defineProperty(_paymentMethodsToDisp, "dutchiePay", 'Pay by Bank'), _defineProperty(_paymentMethodsToDisp, "rethinkPay", 'ReThink Wallet'), _defineProperty(_paymentMethodsToDisp, "linx", 'Linx'), _defineProperty(_paymentMethodsToDisp, "alt36", 'Alt Thirty Six'), _defineProperty(_paymentMethodsToDisp, "canPay", 'CanPay'), _defineProperty(_paymentMethodsToDisp, "paytender", 'Paytender'), _defineProperty(_paymentMethodsToDisp, "aeropay", 'Aeropay'), _paymentMethodsToDisp);
export var orderTypesToDisplayNames = {
  inStorePickup: 'In-Store Pickup',
  curbsidePickup: 'Curbside Pickup',
  driveThruPickup: 'Drive-Thru Pickup',
  delivery: 'Delivery',
  kiosk: 'Kiosk'
};
export var orderTypesToShortNamesV2 = {
  inStorePickup: 'In-Store',
  curbsidePickup: 'Curbside',
  driveThruPickup: 'Drive-Thru',
  delivery: 'Delivery'
};
export var paysafeCardTypes = {
  VI: 'Visa',
  MC: 'Mastercard',
  // mastercard credit
  MD: 'Mastercard',
  // mastercard debit
  AM: 'Amex',
  DI: 'Discover'
};
export var paymentMethodsToDisplayNamesAdmin = _objectSpread(_objectSpread({}, paymentMethodsToDisplayNames), {}, {
  payOnlineMerrco: 'Credit Card (Merrco)',
  payOnlineMoneris: 'Credit Card (Moneris)',
  payOnlineChase: 'Credit Card (Chase)',
  dutchiePay: 'Pay by Bank',
  rethinkPay: 'ReThink Wallet'
});
export var validOrderTypes = Object.keys(orderTypesToDisplayNames);
export var firstValidPaymentOption = function firstValidPaymentOption() {
  var dispensary = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

  if (!dispensary.cashless) {
    return 'cash';
  }

  if (dispensary.payOnlineMerrco) {
    return 'creditCard';
  }

  var foundMethod = _find(_keys(paymentMethodsToDisplayNames), function (key) {
    return dispensary[key];
  }) || _keys(paymentMethodsToDisplayNames)[0];

  return foundMethod;
};
export var defaultDurationEstimates = {
  delivery: {
    lowInMinutes: 35,
    highInMinutes: 45
  },
  pickup: {
    lowInMinutes: 15,
    highInMinutes: 25
  },
  curbsidePickup: {
    lowInMinutes: 15,
    highInMinutes: 25
  }
};
export var defaultActionEstimates = {
  pickup: {
    readyInMinutes: 20,
    rangeInMinutes: 5
  },
  delivery: {
    readyInMinutes: 25,
    rangeInMinutes: 10,
    deliveryTimeInMinutes: 20
  }
}; // valid id's enum - graphql/order/type.js

export var archiveReasons = function archiveReasons() {
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref$phone = _ref.phone,
      phone = _ref$phone === void 0 ? '' : _ref$phone,
      _ref$orderId = _ref.orderId,
      orderId = _ref$orderId === void 0 ? '' : _ref$orderId;

  return [{
    id: 'NOPICKUP',
    reason: 'Customer never picked up the order',
    customerMessage: "Your order #".concat(orderId, " was canceled because it wasn't picked up. Please contact ").concat(phone, " if you have questions or believe this was a mistake.")
  }, {
    id: 'SOLDOUT',
    reason: 'Items on the order were sold out',
    customerMessage: "Your order #".concat(orderId, " contained a sold-out item and has been canceled. Please contact ").concat(phone, " if you have questions or believe this was a mistake.")
  }, {
    id: 'CANCELLED',
    reason: 'Customer asked to cancel the order',
    customerMessage: "Your order #".concat(orderId, " has been canceled. Please contact ").concat(phone, " if you have questions or believe this was a mistake.")
  }, {
    id: 'NOPAYMENT',
    reason: 'Customer didn’t have proper payment',
    customerMessage: "Your order #".concat(orderId, " has been canceled. Please contact ").concat(phone, " if you have questions or believe this was a mistake.")
  }, {
    id: 'NOIDENTIFICATION',
    reason: 'Customer didn’t have proper identification',
    customerMessage: "Your order #".concat(orderId, " has been canceled. Please contact ").concat(phone, " if you have questions or believe this was a mistake.")
  }, {
    id: 'BADINFO',
    reason: 'Customer entered their information incorrectly',
    customerMessage: "Your order #".concat(orderId, " has been canceled. Please contact ").concat(phone, " if you have questions or believe this was a mistake.")
  }, {
    id: 'COMPLIANCEVIOLATION',
    reason: 'The order violated compliance',
    customerMessage: "Your order #".concat(orderId, " has been canceled for compliance reasons. Please contact ").concat(phone, " if you have questions or believe this was a mistake.")
  }, {
    id: 'OTHER',
    reason: 'Other',
    customerMessage: "Your order #".concat(orderId, " has been canceled. Please contact ").concat(phone, " if you have questions or believe this was a mistake.")
  }];
};
export var orderTypeToIndex = {
  pickup: 0,
  delivery: 1
};
export var ORDER_TYPE_DELIVERY = 'delivery';
export var ORDER_TYPE_PICKUP = 'pickup';
export var indexToOrderType = [ORDER_TYPE_PICKUP, ORDER_TYPE_DELIVERY];
export var RewardsConnectionStatuses = {
  ready: 'ready',
  processing: 'processing',
  failed: 'failed',
  succeeded: 'succeeded'
};
export var RewardsBrands = {
  alpineiq: 'alpineiq'
};
export var CHECKOUT_LINE_ITEM_MAPPING = {
  subtotal: 'productSubtotal',
  taxes: 'totalTax',
  discounts: 'discount',
  rewards: 'reward',
  bottleDeposit: 'bottleDepositTaxCents',
  sales: 'salesTax',
  cannabis: 'cannabisTax',
  delivery: 'deliveryFee',
  payment: 'paymentFee'
};