import _isEmpty from "lodash/isEmpty";
import _pick from "lodash/pick";
import _isNil from "lodash/isNil";
import _values from "lodash/values";
import _map from "lodash/map";
import _omitBy from "lodash/omitBy";
import _get from "lodash/get";
import { toJS } from 'mobx';
import { getPersistedValue } from 'shared/utils/persisted-values';
import { hasSaleSpecialForMenuType } from 'shared/helpers/specials';
import { removeTypename, serializeUtmData } from 'shared/helpers/utils';
import { isDutchiePlusKioskOrder } from 'shared/helpers/checkout';
import { ChannelSources } from './constants';
/*
 * called via shared/checkout/actions/onClickSubmit.js
 */

export default function buildOrder(_ref) {
  var _flags$growthEcommU;

  var Cart = _ref.Cart,
      UI = _ref.UI,
      User = _ref.User,
      Checkout = _ref.Checkout,
      paymentId = _ref.paymentId,
      _ref$flags = _ref.flags,
      flags = _ref$flags === void 0 ? {} : _ref$flags;
  var isVariant = Checkout.isVariant,
      checkoutId = Checkout.checkoutId;
  var dispensary = Cart.order.dispensary;
  var removeMedicalCardFieldsAtCheckout = dispensary.removeMedicalCardFieldsAtCheckout;
  var sendPaysafeInput = !!(dispensary.payOnlineMerrco && Cart.order.paymentMethod === 'payOnlineMerrco');
  var guestCustomerFields = null;

  if (Cart.isGuestOrder || isVariant('kiosk')) {
    guestCustomerFields = Cart.order.guestCustomer;
  } // if we have an external order put those values on the guestCustomer field
  // if the MSO gives us that info, lets store it.


  if (User.hasExternalUser) {
    guestCustomerFields = toJS(User.userDetailsFromExternalSource);
  } // If a user clicks the box to save info, then enters a password, then unchecks the box,
  // don't send the password to the server.


  if (!Cart.saveGuestInfo && guestCustomerFields) {
    delete guestCustomerFields.password;
  } // this serializes the utmData to ensure that it is not an array of duplicated values, but a string of that value that
  // was duplicated


  var serializedUtmData = serializeUtmData(Cart.order.utmData);
  var sessionStoredUtmData = getPersistedValue('utmSessionData', {
    parameters: {}
  });

  var sessionUtmParameters = _get(sessionStoredUtmData, 'parameters', {});

  var sessionStorageUtmDataEnabled = (_flags$growthEcommU = flags === null || flags === void 0 ? void 0 : flags['growth.ecomm.use-session-storage-utm-data.rollout']) !== null && _flags$growthEcommU !== void 0 ? _flags$growthEcommU : false; // if possible, dont destructure values here. Logic above the fold could be altering them.

  return {
    proceed: true,
    order: _omitBy(removeTypename({
      dispensaryId: dispensary.id,
      products: _map(_values(Cart.order.cart), function (item) {
        return _omitBy({
          id: item.product._id,
          quantity: parseInt(item.quantity, 10),
          option: item.option,
          additionalOption: item.additionalOption,
          special: hasSaleSpecialForMenuType({
            product: item.product,
            medicalOrder: Cart.order.medicalOrder
          }),
          rawOption: item.rawOption
        }, _isNil);
      }),
      tip: Cart.order.tipValue,
      couponId: _get(Cart.order, 'coupon._id'),
      paysafeInput: sendPaysafeInput ? _get(Cart.order, 'paysafeInput') : undefined,
      paymentMethod: Cart.order.paymentMethod,
      paymentMethodId: Cart.paymentMethodId,
      paymentId: Cart.paymentMethod === 'rethinkPay' ? paymentId : undefined,
      utmData: sessionStorageUtmDataEnabled ? sessionUtmParameters : serializedUtmData,
      embedded: isVariant(['embedded', 'store-front']),
      variantSource: Checkout.variant,
      medicalOrder: Cart.order.medicalOrder,
      medicalCard: removeMedicalCardFieldsAtCheckout ? null : _get(Cart.order, 'medicalCard'),
      driversLicense: _get(Cart.order, 'driversLicense'),
      photoId: _get(Cart.order, 'photoId', User.profile.photoId),
      deliveryOption: Cart.isDelivery,
      address: UI.formattedAddress,
      location: _pick(UI.currentAddress, ['ln1', 'ln2', 'city', 'state', 'zipcode', 'lat', 'lng', 'streetNumber', 'streetName', 'country']),
      specialInstructions: _get(Cart.order, 'specialInstructions', ''),
      isPreviewOrder: UI.previewMode,
      isKioskOrder: isVariant('kiosk') || isDutchiePlusKioskOrder(Cart.orderType, UI.isPlus),
      isGuestOrder: isVariant('kiosk') ? true : Cart.isGuestOrder,
      isExternalOrder: User.hasExternalUser,
      isAfterHoursOrder: Cart.isAfterHoursOrder,
      isCurbsidePickupOrder: Cart.isCurbsidePickup,
      isDriveThruPickupOrder: Cart.isDriveThruPickup,
      guestCustomer: guestCustomerFields,
      checkoutToken: Cart.order.checkoutToken,
      expectedTotal: Cart.costBreakdown.total,
      destinationTerminal: Cart.order.destinationTerminal,
      customerState: Cart.customerState,
      appliedRewards: _map(Cart.order.appliedRewards, function (reward) {
        return _pick(reward, ['id', 'available', 'operator', 'value', 'brand', 'copy', 'rewardType']);
      }),
      reservation: _pick(Cart.order.reservation, ['startTimeISO', 'endTimeISO', 'slotId']),
      reservationType: Cart.reservationType,
      hypurAccessToken: Cart.hypurAccessToken,
      monerisInput: _isEmpty(Cart.monerisInput) ? null : Cart.monerisInput,
      saveGuestInfo: Cart.saveGuestInfo,
      checkoutId: checkoutId,
      isPTPOT: true,
      channelSource: isVariant('mobile-ecomm') ? ChannelSources.retailerMobile : null,
      schedulingData: toJS(Cart.schedulingData)
    }), _isNil)
  };
}