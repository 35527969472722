import _ from 'lodash';
import { observable, computed, action, makeObservable } from 'mobx';
import Router from 'next/router';

import { changeNativeTabToShop } from 'src/mobile-ecomm/hooks/use-mobile-native-bridge';
import { isPrerenderIoBot, isSearchEngineBot } from 'src/utils/bots';
import { guessTimezone } from 'shared/helpers/date-time';
import { getPersistedValue, setPersistedValue } from 'shared/utils/persisted-values';
import { formatAddress, formatAddressWithoutApt, formatAddressForCheckout } from 'shared/helpers/address';
import ROUTES from 'src/routes';
import { InterfaceVariants } from '../constants/interface-variants';

// store global ephemeral state values such as modal open/close
export default class UIStore {
  // router info copied over for easy access / mobx memoization
  @observable href = '';
  @observable route = '';

  // if we're in an iframe or not, but ssr safe
  @observable isIframe = false;
  @observable isIframeSDK = false;

  // global modal open fo shodal
  @observable viewingGuestOrderSuccess = false;
  @observable showClosedModal = false;
  @observable showMobileFilters = false;
  @observable showPausedOrdersModal = false;
  @observable showKioskCheckoutModal = false;
  @observable showKioskOrderSuccess = false;
  @observable showReinventedModal = false;
  @observable showLocationSettingsDropdown = false;
  @observable showAlpineIQTermsModal = false;
  @observable showCartDrawer = false;
  @observable cartButtonRef = null;
  @observable forceAgeGate = false;

  // These are interstitial modals, will have passed promise props when open {resolve, reject}
  @observable showResidentialVerificationModal = { open: false };
  @observable showChangeEmailPasswordPrompt = { open: false };
  @observable showSearchInput = false;
  @observable menuTypeSwitchWarningObj = { open: false };
  @observable currentMenuDrawerTab = 1;
  @observable showOutsideDeliveryAreaModal = { open: false };

  // Mobile drawers
  @observable showOrderTypeDrawer = false;
  @observable showStoreTypeDrawer = false;
  @observable showDispensariesFiltersDrawer = false;
  @observable showMarketplaceMobileSearchDrawer = false;

  // Added for compatibility to help with surface control in v3
  @observable isGlobalHeaderInView = false;
  @observable isDispensaryHeaderInView = true;
  @observable previewMode = false;
  @observable viewOnlyMode = false;
  @observable lastDispensary = null;
  @observable embeddedCName = null;

  // checkout stuff
  @observable phoneNumberConfirmationObj = { open: false };
  @observable tempAddress = '';
  @observable apt = '';
  @observable timezone = guessTimezone();

  // address is *ONLY* filled in while in the checkout view
  // use cart.address instead
  @observable currentAddress = null;
  @observable loadingOrderType = false;

  // toggle for category menu layout.
  @observable menuLayout = `list`;

  @observable ernieQueue = [];
  @observable isScrolling = false;
  @observable externalReferrer = false;
  @observable isUsingExternalClickHandlerLogin = false;

  @observable iframeParentUrl = null;
  @observable iframeParentHref = null;
  @observable iframeHeight = null;
  @observable iframeHeightOverride = false;
  @observable showEmptySearchWhiteSpace = false;
  @observable previousSearchTerm = null;
  @observable shouldRestorePreviousScroll = false;
  @observable subheaderIsSticking = false;
  @observable productCount = getPersistedValue(`productCount`, 0);
  // positioning data for amplitude
  @observable productPosition = null;
  // For managing the source of adding to cart on product detail
  @observable activeProductSource = null;
  // dutchiePay
  @observable showDutchiePaySignupModal = false;
  @observable showDutchiePayInstoreTaxInfo = false;
  @observable showDutchiePayEditAddressModal = false;
  @observable showDutchiePayLeaveModal = false;
  @observable showDutchiePayLearnMoreModal = false;
  @observable showDutchiePayCtaModal = false;
  @observable showDutchiePayQrCodeInKiosk = false;
  @observable showDutchiePayKioskOrderSuccess = false;

  // Retailer Mobile App
  @observable isMobileEcommAppNotificationsEnabled = false;

  // Dutchie Plus - isolatedCheckout
  @observable hideLogin = false;

  // global first render detection
  @observable isFirstRender = true;

  constructor(router) {
    makeObservable(this);
    const { asPath, route } = router;

    this.setPath(asPath, route);
  }

  @action.bound setIsMobileEcommAppNotificationsEnabled(value) {
    if (this.isMobileEcommApp) {
      this.isMobileEcommAppNotificationsEnabled = value;
    }
  }

  @action.bound openDutchiePaySignUpModal() {
    this.showDutchiePaySignupModal = true;
  }

  @action.bound openDutchiePayEditAddressModal() {
    this.showDutchiePaySignupModal = true;
    this.showDutchiePayEditAddressModal = true;
  }

  @action.bound updateShowDutchiePayQrCodeInKiosk = (value) => {
    this.showDutchiePayQrCodeInKiosk = value;
  };

  @action.bound updateShowDutchiePayKioskOrderSuccess = (value) => {
    if (value === true) {
      this.showDutchiePayQrCodeInKiosk = false;
    }
    this.showDutchiePayKioskOrderSuccess = value;
  };

  @action.bound closeDutchiePaySignUpModal() {
    this.showDutchiePaySignupModal = false;
    this.showDutchiePayEditAddressModal = false;
  }

  @action.bound openDutchiePayLeaveModal() {
    this.showDutchiePayLeaveModal = true;
  }

  @action.bound closeDutchiePayLeaveModal() {
    this.showDutchiePayLeaveModal = false;
  }

  @action.bound openDutchiePayLearnMoreModal() {
    this.showDutchiePayLearnMoreModal = true;
  }

  @action.bound closeDutchiePayLearnMoreModal() {
    this.showDutchiePayLearnMoreModal = false;
  }

  @action.bound openDutchiePayCtaModal() {
    this.showDutchiePayCtaModal = true;
  }

  @action.bound closeDutchiePayCtaModal() {
    this.showDutchiePayCtaModal = false;
  }
  @action.bound openDutchiePayInstoreTaxInfo() {
    this.showDutchiePayInstoreTaxInfo = true;
  }

  @action.bound closeDutchiePayInstoreTaxInfo() {
    this.showDutchiePayInstoreTaxInfo = false;
  }

  @action.bound
  openOrderTypeModal(viewingModalName = `viewingClosedModal`) {
    const keyName = {
      viewingClosedModal: `showClosedModal`,
      viewingPausedOrdersModal: `showPausedOrdersModal`,
    }[viewingModalName];

    if (keyName) {
      this[keyName] = true;
    }
  }

  @action.bound
  setEmbeddedCName(cName) {
    this.embeddedCName = cName;
  }

  @action.bound
  openPhoneNumberConfirmationModal({ confirmed }) {
    return new Promise((resolve) => {
      this.phoneNumberConfirmationObj = { open: true, confirmed, resolve };
    });
  }

  @action
  openPausedOrdersModal() {
    this.showPausedOrdersModal = true;
  }

  @action.bound
  toggleLocationSettingsDropdown() {
    this.showLocationSettingsDropdown = !this.showLocationSettingsDropdown;
  }

  @action.bound
  openChangeEmailPasswordPrompt() {
    return new Promise((resolve) => {
      this.showChangeEmailPasswordPrompt = { open: true, resolve };
    });
  }

  @action.bound
  openCartDrawer() {
    this.showCartDrawer = true;
  }

  @action.bound
  closeCartDrawer() {
    this.showCartDrawer = false;
  }

  @action.bound
  setCartButtonRef(ref) {
    this.cartButtonRef = ref;
  }

  @action.bound
  clearCartButtonRef() {
    this.cartButtonRef = null;
  }

  @action.bound
  openOrderTypeDrawer() {
    this.showOrderTypeDrawer = true;
  }

  @action.bound
  closeOrderTypeDrawer() {
    this.showOrderTypeDrawer = false;
  }

  @action.bound
  openStoreTypeDrawer() {
    this.showStoreTypeDrawer = true;
  }

  @action.bound
  closeStoreTypeDrawer() {
    this.showStoreTypeDrawer = false;
  }

  @action.bound
  openDispensariesFiltersDrawer() {
    this.showDispensariesFiltersDrawer = true;
  }

  @action.bound
  closeDispensariesFiltersDrawer() {
    this.showDispensariesFiltersDrawer = false;
  }

  @action.bound
  openMarketplaceMobileSearchDrawer() {
    this.showMarketplaceMobileSearchDrawer = true;
  }

  @action.bound
  closeMarketplaceMobileSearchDrawer() {
    this.showMarketplaceMobileSearchDrawer = false;
  }

  @action.bound
  openMenuSwitchWarningModal() {
    return new Promise((resolve) => {
      this.menuTypeSwitchWarningObj = { open: true, resolve };
    });
  }

  @action.bound
  openShowOutsideDeliveryAreaModal() {
    return new Promise((resolve) => {
      this.showOutsideDeliveryAreaModal = { open: true, resolve };
    });
  }

  @action
  setGlobalHeaderInView(inView) {
    this.isGlobalHeaderInView = inView;
  }

  @action
  setDispensaryHeaderInView(inView) {
    this.isDispensaryHeaderInView = inView;
  }

  @action
  setShouldRestorePreviousScroll(shouldRestore) {
    this.shouldRestorePreviousScroll = shouldRestore;
  }

  @action
  setSubheaderIsSticking(isSticking) {
    this.subheaderIsSticking = isSticking;
  }

  @action showErnie = (msg = `Something went wrong. Please contact support.`, type = `danger`, timeout) => {
    const numWords = _.words(msg).length;
    const time = Math.round((60 / 150) * numWords * 1000 + 300); // assuming 150 wpm and reaction time of 0.3 sec
    // explanation: (60 sec / 150 words) * numWords * (1000 millisecs / 1 sec) + 0.3 sec
    this.ernieQueue.push({ msg, type, timeout: timeout || time });
  };

  @action
  embeddedEventDispatcher = (event, payload) => {
    if (window.parent === window) {
      return true;
    }

    window.parent.postMessage(JSON.stringify({ event, payload }), '*');
    return true;
  };

  // navigation
  @action
  showMenu(dispensaryId, replace) {
    if (this.isMobileEcommApp) {
      changeNativeTabToShop();
      return;
    }

    if (replace) {
      return Router.replace(`/${this.dispensaryRootUrl}/[cName]`, `/${this.dispensaryRootUrl}/${dispensaryId}`);
    }
    return Router.push(`/${this.dispensaryRootUrl}/[cName]`, `/${this.dispensaryRootUrl}/${dispensaryId}`);
  }

  @action
  showOrder(orderId, replace) {
    const { ORDERS, ORDER } = ROUTES;
    if (replace) {
      Router.replace(ORDER, `${ORDERS}/${orderId}?view=status`);
    } else {
      Router.push(ORDER, `${ORDERS}/${orderId}?view=status`);
    }
  }

  @action
  showPreferences(replace) {
    const { PREFERENCES } = ROUTES;
    if (replace) {
      Router.replace(PREFERENCES);
    } else {
      Router.push(PREFERENCES);
    }
  }

  @action
  showHome(replace) {
    if (replace) {
      Router.replace('/');
    } else {
      Router.push('/');
    }
  }

  @action
  showShop(replace) {
    const { SHOP } = ROUTES;
    if (replace) {
      Router.replace(SHOP);
    } else {
      Router.push(SHOP);
    }
  }

  @action.bound
  goBack() {
    Router.back();
  }

  @action.bound
  isVariant(variant) {
    if (_.isArray(variant)) {
      return _.includes(variant, this.variant);
    }
    return this.variant === variant;
  }

  @action.bound
  showResidentialStatusModal() {
    // Here for v2 store compatibility
    if (this.residentialStatus !== 'VERIFIED' || this.currentAddress?.residentialStatus !== 'VERIFIED') {
      return new Promise((resolve, reject) => {
        this.showResidentialVerificationModal = { open: true, resolve, reject };
      });
    }
  }

  // used in shared checkout
  @action.bound
  hasDeliveryAddress({ location = this.currentAddress } = {}) {
    const addressLine1 = location?.ln1 || '';

    const streetNumber = !_.isEmpty(_.trim(addressLine1)) && _.split(addressLine1, ' ')[0];
    const streetNumberValidationRegex = /^\d+-?\d*$/;
    const hasValidStreetNumber = streetNumber && streetNumberValidationRegex.test(streetNumber);

    const hasLocationLng = location?.lng;

    const noValidDeliveryAddress = !hasLocationLng || !hasValidStreetNumber;

    if (noValidDeliveryAddress) {
      return false;
    }

    return true;
  }

  @action.bound
  resetAddress() {
    this.currentAddress = {};
  }

  @action
  setLoadingOrderType(loading) {
    this.loadingOrderType = !!loading;
  }

  @action
  setCurrentMenuDrawerTab(tab) {
    this.currentMenuDrawerTab = tab;
  }

  @action
  setMenuLayout(layoutType = `cards`) {
    this.menuLayout = layoutType;
  }

  @action
  setProductPosition(newEntry) {
    this.productPosition = newEntry;
  }

  @action.bound
  setProductCount(count) {
    this.productCount = count;
    setPersistedValue(`productCount`, count);
  }

  @action
  setViewMode(query) {
    this.previewMode = query?.preview === 'true';
    this.viewOnlyMode = query?.viewOnlyMode === 'true';
  }

  @action
  setPath(href, route) {
    this.href = href;
    this.route = route;
  }

  @action updateAddress({ location, residentialStatus = 'UNVERIFIED' }) {
    if (location?.geometry?.coordinates) {
      const [lng, lat] = location.geometry.coordinates;
      location = { ...location, lng, lat }; // eslint-disable-line
    }
    this.currentAddress = { ...location, residentialStatus };
    this.tempLocation = { ...location, residentialStatus };
    this.tempAddress = this.formattedAddressWithoutApt;
    if (!_.isEmpty(location.ln2)) {
      this.apt = location.ln2;
    } else if (location.apt) {
      this.apt = location.apt;
    }
  }

  @action setHideLogin(value) {
    this.hideLogin = value;
  }

  @computed
  get dispensaryRootUrl() {
    if (this.isDemo) {
      return `demo`;
    }
    return {
      default: `dispensary`,
      'store-front': `stores`,
      embedded: `embedded-menu`,
      kiosk: `kiosks`,
      'mobile-ecomm': `mobile-ecomm`,
    }[this.variant];
  }

  @computed
  get isMobileEcommApp() {
    return _.startsWith(this.route, '/mobile-ecomm/');
  }

  @computed
  get isCartPage() {
    return _.includes(this.route, '/cart');
  }

  @computed
  get isProductDetailPage() {
    return _.includes(this.route, ROUTES.PRODUCT_ID);
  }

  @computed
  get isMessaging() {
    return _.startsWith(this.route, '/messaging/');
  }

  // Core menu variant checks
  // we use href instead of route for these bc route is changed to /_error in the case of a bad path
  // we need an accurate variant in this case to serve the right layout on the whoops page
  @computed
  get isKiosk() {
    return _.startsWith(this.href, '/kiosks/');
  }

  @computed
  get isEmbedded() {
    if (_.startsWith(this.href, '/embedded-menu/')) {
      return true;
    }
    if (this.isIframe) {
      return true;
    }
    return false;
  }

  @computed
  get isStoreFront() {
    return _.startsWith(this.href, '/stores/');
  }
  // end core menu variant checks

  @computed
  get onBrandsPage() {
    return _.includes(this.route, ROUTES.BRANDS);
  }

  @computed
  get onSearchPage() {
    return _.endsWith(this.route, ROUTES.ALL_PRODUCTS_SEARCH);
  }

  @computed
  get analyticsMenuVariant() {
    return this.variant === 'default' ? `main` : this.variant;
  }

  @computed
  get isEmbeddedCarousel() {
    return this.route.indexOf('/carousels/') > 0;
  }

  @computed
  get isDemo() {
    return _.startsWith(this.route, '/demo/');
  }

  @computed
  get isPlus() {
    return _.startsWith(this.route, '/checkouts/');
  }

  @computed
  get isMarketplace() {
    return _.startsWith(this.route, ROUTES.SHOP);
  }

  @computed
  get isMarketplaceDispensary() {
    return _.startsWith(this.route, ROUTES.SHOP_DISPENSARY);
  }

  @computed
  get isCareersPage() {
    return _.startsWith(this.route, '/careers');
  }

  @computed
  get isAccountPage() {
    return _.includes(this.route, `/user/[accountTab]`);
  }

  @computed
  get isSpecialPage() {
    return _.endsWith(this.route, ROUTES.SPECIAL_ID);
  }

  @computed
  get isAboutPage() {
    return _.startsWith(this.route, '/about');
  }

  @computed
  get isLocationSelectorPage() {
    return _.includes(this.route, '/locations');
  }

  @computed
  get isNewsPage() {
    return _.startsWith(this.route, '/news');
  }

  @computed
  get isOnboardingPage() {
    return _.includes(this.route, '/getstarted');
  }

  @computed
  get isDutchieMain() {
    return this.variant === InterfaceVariants.dutchieMain;
  }

  @computed
  get isCheckoutPage() {
    return _.includes(this.route, '/checkout');
  }

  @computed
  get variant() {
    if (this.isPlus) {
      return InterfaceVariants.plus;
    }

    if (this.isStoreFront) {
      return InterfaceVariants.storeFront;
    }

    if (this.isEmbedded) {
      return InterfaceVariants.embedded;
    }

    if (this.isKiosk) {
      return InterfaceVariants.kiosk;
    }

    if (this.isMessaging) {
      return InterfaceVariants.messaging;
    }

    if (this.isDemo) {
      return InterfaceVariants.embedded;
    }

    if (this.isMobileEcommApp) {
      return InterfaceVariants.mobileEcomm;
    }

    return InterfaceVariants.dutchieMain;
  }

  @computed
  get showAgeRestrictionModal() {
    // TODO: rename, this is a feature not a modal state
    if (
      isPrerenderIoBot() ||
      isSearchEngineBot() ||
      this.isAboutPage ||
      this.isCareersPage ||
      this.isNewsPage ||
      this.isOnboardingPage ||
      this.isMobileEcommApp
    ) {
      return false;
    }
    return this.forceAgeGate || this.isDutchieMain;
  }

  @computed
  get hasAddress() {
    return !!this.currentAddress?.zipcode;
  }

  @computed
  get formattedAddress() {
    if (!this.hasAddress) {
      return '';
    }
    const { ln1, ln2, city, state, zipcode, country } = this.currentAddress;
    return formatAddress({ ln1, ln2, city, state, zipcode, country });
  }

  @computed
  get formattedAddressWithoutApt() {
    if (!this.hasAddress) {
      return '';
    }
    const { ln1, city, state, zipcode, country } = this.currentAddress;
    return formatAddressWithoutApt({ ln1, city, state, zipcode, country });
  }

  @computed
  get formattedAddressForCheckout() {
    if (!this.hasAddress) {
      return '';
    }
    const { ln1, ln2 } = this.currentAddress;
    return formatAddressForCheckout({ ln1, ln2 });
  }

  @computed
  get address() {
    return this.formattedAddressWithoutApt;
  }

  @computed
  get globalHeaderHeight() {
    const { DISPENSARY_CNAME, DISPENSARIES } = ROUTES;
    return _.some([DISPENSARY_CNAME, DISPENSARIES], (routesWithGlobalHeader) =>
      _.includes(this.route, routesWithGlobalHeader)
    )
      ? document?.querySelector(`#global-header`)?.offsetHeight
      : null;
  }

  @computed
  get dispensaryHeaderHeight() {
    const { DISPENSARY_CNAME } = ROUTES;
    return _.includes(this.route, DISPENSARY_CNAME)
      ? document?.querySelector(`#dispensary-header`)?.offsetHeight
      : null;
  }
}
