import _ from 'lodash';
import React from 'react';
import { observer } from 'mobx-react-lite';
import { useRouter } from 'next/router';

import useDispensary from 'src/dispensary/hooks/use-dispensary';
import useViewportVisibility from 'hooks/use-viewport-visibility';
import useUI from 'hooks/use-ui';
import useTranslation from 'hooks/use-translation';
import useDispensaryProducts from 'src/dispensary/hooks/use-dispensary-products';
import useDispensaryFlag from 'shared/hooks/use-dispensary-flag';
import usePaths from 'src/hooks/use-paths';

import { useQuickAddToCart } from 'src/components/product-carousel/use-quick-add-to-cart';
import { getItemListName } from 'src/utils/analytics/trackers/internal-gtm-tracker/helpers/item-list-name';
import { tracker } from 'src/utils/analytics';
import { ProductRecommendationSource } from 'src/utils/analytics/trackers/internal-gtm-tracker/internal-gtm-tracker.types';

import { CarouselProductCard } from 'src/components/product-card';
import { CARD_SIZES } from 'src/components/product-card/product-card.constants';
import CarouselSlider from 'components/carousel-slider';
import EmptyLoadingState from 'components/empty-loading-state';
import { CarouselHeader } from '../carousel-header';
import { applySettings, defaultFilters } from '../carousels.utils';
import { CardCarouselItem, Section } from '../carousels.styles';

const sortProducts = (fetched, products) =>
  _.sortBy(
    fetched,
    (a, b) =>
      _.findIndex(products, (product) => product === a?.id) - _.findIndex(products, (product) => product === b?.id)
  );

const generateSlides = ({ dispensary, sortedProducts, sectionIndex, UI, handleAddToCart, trackerSource }) => {
  const isEmbeddedCarousel = UI?.isEmbeddedCarousel ?? false;

  return _.map(applySettings(sortedProducts), (product, index) => (
    <CardCarouselItem key={product.id}>
      <CarouselProductCard
        dimensions={CARD_SIZES.default}
        dispensary={dispensary}
        product={product}
        width='100%'
        productIndex={index}
        sectionIndex={sectionIndex}
        onQuickAddClick={() => {
          UI.setProductPosition({
            productId: product.id,
            productIndex: index,
            sectionIndex,
          });

          tracker.setContext({
            activeProductPosition: index,
          });
          handleAddToCart(product, trackerSource);
        }}
        onClick={() => {
          UI.setProductPosition({
            productId: product.id,
            productIndex: index,
            sectionIndex,
          });

          UI.activeProductSource = trackerSource;

          tracker.setContext({
            activeProductPosition: index,
          });
        }}
        trackerSource={trackerSource}
        isEmbeddedCarousel={isEmbeddedCarousel}
      />
    </CardCarouselItem>
  ));
};

export const CustomCarousel = observer(({ section, sectionIndex }) => {
  const UI = useUI();

  const { label, products, hideTitle, id } = section;
  const { t } = useTranslation();
  const { dispensary } = useDispensary();
  const { ref, hasBeenVisible } = useViewportVisibility();
  const { href, route } = usePaths({
    menuSection: {
      id,
    },
  });
  const shouldShowViewAllLink = useDispensaryFlag(`rollout.custom-carousel-page`, dispensary?.id);

  const { handleAddToCart } = useQuickAddToCart();
  const router = useRouter();

  const { products: fetchedProducts, loading } = useDispensaryProducts({
    filters: { ...defaultFilters, productIds: products },
    skip: !hasBeenVisible,
  });

  const hasProducts = fetchedProducts?.length > 0;
  if (!hasProducts && !loading && hasBeenVisible) {
    return null;
  }

  const sortedProducts = sortProducts(fetchedProducts, products);

  const trackerSource = getItemListName({
    router,
    source: ProductRecommendationSource.native,
    section: label,
  });

  const slides = generateSlides({
    dispensary,
    sortedProducts,
    sectionIndex,
    UI,
    handleAddToCart,
    trackerSource,
  });

  return (
    <div ref={ref} style={{ minHeight: '420px' }}>
      {hasBeenVisible && (
        <EmptyLoadingState isLoading={loading} page='productCarousel'>
          <Section data-testid='product-carousel'>
            <CarouselHeader
              t={t}
              label={label}
              showTitle={hideTitle !== `true`}
              linkLabel={label}
              href={href}
              route={route}
              showLink={shouldShowViewAllLink}
            />
            <CarouselSlider>{slides}</CarouselSlider>
          </Section>
        </EmptyLoadingState>
      )}
    </div>
  );
});
