import _ from 'lodash';
import TinyColor from 'tinycolor2';
import { createContext } from 'react';

import useUI from 'shared/hooks/use-ui';
import { useGetWebCustomizationSettings } from 'src/theme/theme.utils';
import { carouselProductLimit } from 'shared/constants/products';
import { getColors } from '../../theme/colors';

const colors = getColors();

export const defaultFilters = {
  perPage: carouselProductLimit,
  sortBy: `popularSortIdx`,
  useCache: true,
};

export const CarouselConfigurationContext = createContext({ useLazyLoading: true });

// NOTE: the server is responsible for preventing low inventory items from appearing here
// limit carousels to 25 products
export const applySettings = (products) => products.splice(0, 25);

export const checkNeedToMakeEdibleIngestible = (dispensaryState, category) =>
  dispensaryState === 'PA' && _.toLower(category) === 'edible';

export const replaceEdibleWithIngestible = (categoryProperty) => {
  if (_.includes(categoryProperty, `Edible`)) {
    return _.replace(categoryProperty, `Edible`, `Ingestible`);
  }

  if (_.includes(categoryProperty, `edible`)) {
    return _.replace(categoryProperty, `edible`, `ingestible`);
  }

  return categoryProperty;
};

export const useCarouselTheme = (dispensary) => {
  const { isDutchieMain } = useUI();
  const { colors: dispensaryColors } = useGetWebCustomizationSettings(dispensary)();
  const { buttonsLinks: baseColor } = dispensaryColors ?? {};

  const dispensaryColor = TinyColor(baseColor);
  const defaultColor = TinyColor(colors.primaryBlue);
  const defaultLoadingColor = TinyColor(`#8eb2c7`);

  // Use custom dispensary theme on embedded/storefront menu carousels, if available
  if (!isDutchieMain && dispensaryColor.isValid()) {
    const backgroundColor = TinyColor.mix(_.clone(dispensaryColor), `#fff`, 90);
    const loadingColor = TinyColor(_.clone(dispensaryColor)).setAlpha(0.1);

    return {
      backgroundColor: backgroundColor.toString(),
      loadingColor: loadingColor.toRgbString(),
      primaryColor: dispensaryColor.toString(),
      primaryColorHover: dispensaryColor.darken(10).toString(),
      textColor: colors.primaryBlack,
    };
  }

  return {
    backgroundColor: colors.grey[95],
    loadingColor: defaultLoadingColor.setAlpha(0.2).toRgbString(),
    primaryColor: defaultColor.toString(),
    primaryColorHover: defaultColor.darken(15).toString(),
  };
};
